import React from "react"
import Layout from "../components/layout"

import Sezione from "../components/sezione"
import SEO from "../components/seo"
import CardPagina from "../components/cardPagina"
import BanneronePage from "../components/banneronePage"
import TitoloPagina from "../components/titoloPagina"
import BottoneBianco from "../components/bottoneBianco"

import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Tab,
  ListGroup,
  Tabs,
} from "react-bootstrap"

const AlberghiAgriturismi = () => (
  <Layout isPagina={true}>
    <SEO
      title="Bonus Alberghi 80% - Incentivi impianti fotovoltaici per alberghi ed agriturismi Lazio: Roma, Pomezia, Aprilia, Latina, Anzio, Nettuno"
      description="Incentivi per la realizzazione di impianti fotovoltaici a favore di alberghi ed agriturismi a Roma, Pomezia, Aprilia, Latina, Anzio, Nettuno"
      location="alberghi-e-agriturismi/"
    />
    <BanneronePage title="Alberghi e Agriturismi" />
    <Container className="mt-4 mb-4">
      <TitoloPagina title="Incentivi impianti fotovoltaici per alberghi ed agriturismi Lazio: Roma, Pomezia, Aprilia, Latina, Anzio, Nettuno" />
      <div className="text-center">
        <img
          src="../bonus-ristrutturazione-hotel-agriturismi.jpg"
          alt="Bonus Ristrutturazione Alberghi ed Agriturismi"
          className="mt-2 rounded img-fluid"
        />
      </div>
      <CardPagina variante="bordoLeft bordoRight">
        <p>
          Con le leggi di Stabilità degli ultimi anni, il Credito di imposta/Bonus Fiscale
          relativo alle spese per la riqualificazione delle{" "}
          <strong>imprese alberghiere</strong> è confermato anche per i periodi
          d’imposta 2024 e 2025, con i seguenti vantaggi aggiuntivi:
        </p>
        <ul>
          <li>
            la misura dell’agevolazione è elevata al <b>80%</b> dell'intero
            importo dell'intervento energetico sostenuto;
          </li>
          <li>
            il credito di imposta viene rimborsato in{" "}
            <b>in sole due annualità di pari importo successive alla realizzazione degli interventi</b>, diminuendo
            notevolmente i tempi di rientro degli interventi come il
            fotovoltaico aziendale o la climatizzazione degli ambienti;
          </li>
          <li>
            sono compresi tra i beneficiari del credito d’imposta anche le
            strutture che svolgono <b>attività agrituristica</b>;
          </li>
          <li>
            gli interventi ammessi possono avere anche la finalità di realizzare
            opere di riqualificazione antisismica e di{" "}
            <b>riqualificazione energetica.</b>
          </li>
        </ul>
        <p>
          Il credito di imposta del 80% recuperato in soli 2 anni, sommato
          all'AUTOCONSUMO (risparmio dei costi in fattura dell’energia elettrica
          fino all'80-90%), allo SCAMBIO SUL POSTO (remunerazione dell'energia
          ceduta in rete) ed alla DEDUCIBILITA' /SUPER AMMORTAMENTO del 106%
          (bene strumentale), consente di ridurre il tempo di ammortamento
          dell'investimento energetico per un impianto fotovoltaico a soli 3
          anni con la formula denominata "a costo zero" o "autofinanziata" in
          cui le rate dell'eventuale finanziamento agevolato sono ampiamente
          inferiori alle entrate prodotte dall'impianto, per poi usufruire di
          oltre 25 anni di entrate utili nette garantite!!
        </p>
      </CardPagina>
      <CardPagina variante="white bordoTop mt-5">
        <Row>
          <Col xs={12} className="mt-3">
            <p className="titoloGrande text-center gridParity">
              CONSULENZA, PROGETTAZIONE, FINANZIAMENTO, REALIZZAZIONE E
              MANUTENZIONE:
              <br />
              Impianti fotovoltaici aziendali e riqualificazione energetica per ALBERGHI ed AGRITURISMI del
              LAZIO <br />
              <strong>
                Tempo di rientro di 2/3 anni con anticipo zero, in attivo dal primo giorno <br />
                Formula Autofinanziata
              </strong>
            </p>
          </Col>
          <Col xs={12}>
            <BottoneBianco
              className="mt-3 mx-auto text-center variantePage"
              title="Richiedi Informazioni"
              link="richiesta-informazioni"
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col sm={6}>
            <img
              className="rounded mt-1 img-fluid"
              src="../agriturismo.jpg"
              alt="impianti fotovoltaici alberghi ed agriturismi"
            />
          </Col>
          <Col sm={6}>
            <img
              className="rounded mt-1 img-fluid "
              src="../credito-imposta-alberghi-lazio.jpg"
              alt="impianti fotovoltaici alberghi ed agriturismi"
            />
          </Col>
        </Row>
      </CardPagina>
    </Container>
  </Layout>
)

export default AlberghiAgriturismi
